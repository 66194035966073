// Global Variables =================================================

var ga_scroll_25 = false;
var ga_scroll_50 = false;
var ga_scroll_75 = false;
var ga_scroll_100 = false;

// General document function ========================================

$(document).ready(function() {

    // Scroll to the selected anchor ================================

    $('a[href^="#"]').on('click', function(event) {
        var target = $( $(this).attr('href') );
        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000);
        }
    });

    // Show and hide form on click ==================================

        $("#mailLink").click(function(event){
            event.preventDefault();
            $(".formWrapper").show();
        });

        $(".close").click(function(event){
            event.preventDefault();
            $(".formWrapper").hide();
        });


// Show and hide form on click ==================================

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 170) {
            $(".mainNav").addClass("darkHeader");
        } else {
            $(".mainNav").removeClass("darkHeader");
        }


        var docHeight = $(document).height();
        var winHeight = $(window).height();
        var scrollPercent = Math.round((scroll) / (docHeight - winHeight)*100);

        if(scrollPercent >= 25 && !ga_scroll_25){
            ga_scroll_25 = true;
            dataLayer.push({'event': 'event-scroll25'});
        }
        if(scrollPercent >= 50 && !ga_scroll_50){
            ga_scroll_50 = true;
            dataLayer.push({'event': 'event-scroll50'});
        }
        if(scrollPercent >= 75 && !ga_scroll_75){
            ga_scroll_75 = true;
            dataLayer.push({'event': 'event-scroll75'});
        }
        if(scrollPercent == 100 && !ga_scroll_100){
            ga_scroll_100 = true;
            dataLayer.push({'event': 'event-scroll100'});
        }

    });

    $('.mail').click(function(){
        dataLayer.push({'event': 'event-mailcontato'});
    });
    $('.nif').click(function(){
        dataLayer.push({'event': 'event-clickNIF'});
    });
    $('.phone').click(function(){
        dataLayer.push({'event': 'event-clicktelefone'});
    });

    $( "button.steps" ).on( "click", function() {
        $('div.steps-popup').addClass( "open" );
    });

    $( "div.steps-popup .cross" ).on( "click", function() {
        $('div.steps-popup').removeClass( "open" );
    });

    $( "div.steps-popup .next" ).on( "click", function() {
        $('div.steps-popup li.active').removeClass( "active" ).next().addClass('active');
    });

    $( "div.steps-popup .prev" ).on( "click", function() {
        $('div.steps-popup li.active').removeClass( "active" ).prev().addClass('active');
    });

    $( "div.rules-button" ).on( "click", function() {
        $('div.popup').addClass( "open" );
    });

    $( "div.popup .rules .popup-close" ).on( "click", function() {
        $('div.popup').removeClass( "open" );
    });

});
